type EncapsulatedStringObject = Record<string, string | object>;
export function convertKeysToDotNotation(object: EncapsulatedStringObject, prefix: string = ""): Record<string, string> {
  const result: Record<string, string> = {};
  Object.keys(object).forEach((key) => {
    const newPrefix = prefix ? `${prefix}.${key}` : key;
    const value = object[key];
    if (typeof value === "object") {
      Object.assign(result, convertKeysToDotNotation(object[key] as EncapsulatedStringObject, newPrefix));
    } else {
      result[newPrefix] = value;
    }
  });
  return result;
}

export const isAdmin = (role: string) => {
  return role === "ADMIN" || role === "SUPER_ADMIN";
};

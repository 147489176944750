import { useEffect, useState } from "react";
import { Content, FlexboxGrid, Header, IconButton, Loader, Message, Nav, toaster } from "rsuite";
import DefaultLayout from "../../layouts/DefaultLayout";
import CampaignManagerGeneralTab from "./tabs/General";
import CheckIcon from "@rsuite/icons/Check";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { getOneCampaign, saveCampaign } from "../../api/campaign.api";
import CampaignManagerTargetingTab from "./tabs/Targeting";
import CampaignManagerLogTab from "./tabs/Log";
import { useGlobalState } from "../../state";
import { isAdmin } from "../../lib/utils";

const defaultFormValue = {
  id: "",
  general: {
    targetUrl: "",
    status: "DISABLED",
  },
  targeting: {
    countries: [],
  },
};

const CampaignManagerPage: React.FC = () => {
  const navigate = useNavigate();

  const globalState = useGlobalState();

  const [activeTab, setActiveTab] = useState("general");
  const [saving, setSaving] = useState(false);

  const { id, accountId = "" } = useParams();
  const [formValue, setFormValue] = useState(_.cloneDeep(defaultFormValue));

  const [loading, setLoading] = useState(id ? true : false);

  const fetchCampaign = async () => {
    if (!id) {
      return;
    }

    try {
      const { data } = await getOneCampaign(accountId, id);
      setFormValue(
        _.merge(_.cloneDeep(defaultFormValue), {
          id: data.id,
          general: {
            targetUrl: data.targetUrl.replace("http://", "").replace("https://", ""),
            status: data.status,
          },
          targeting: {
            countries: data?.targetCountries || [],
          },
        })
      );
    } catch (err: any) {
      toaster.push(
        <Message type="error" header="Error" showIcon>
          {err.message}
        </Message>
      );
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchCampaign();
  }, [id]);

  const save = async () => {
    if (!formValue.general.targetUrl) {
      alert("give your campaign a target url you fool");
      return;
    }

    setSaving(true);

    try {
      // @ts-ignore
      const result = await saveCampaign(accountId, formValue);
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Campaign saved!
        </Message>
      );

      if (id) {
        fetchCampaign();
      } else {
        navigate(`/${accountId}/campaigns/manager/${result.data.id}`);
      }
    } catch (err) {
      console.log(err);
    }

    setSaving(false);
  };

  if (loading) {
    return (
      <DefaultLayout>
        <Loader content="Loading..." />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <Header>
        <h2>Campaign Manager</h2>
      </Header>
      <Content>
        <FlexboxGrid justify="end" style={{ marginBottom: 20 }}>
          <FlexboxGrid.Item>
            <IconButton icon={<CheckIcon />} appearance="primary" color="green" loading={saving} onClick={() => save()}>
              Save Campaign
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Nav activeKey={activeTab} onSelect={setActiveTab} appearance="subtle" style={{ marginBottom: 20 }}>
          <Nav.Item eventKey="general">General</Nav.Item>
          <Nav.Item eventKey="targeting">Targeting</Nav.Item>
          {/* @ts-ignore */}
          {id && isAdmin(globalState.user.get().role) && <Nav.Item eventKey="log">Log</Nav.Item>}
        </Nav>
        {activeTab === "general" && (
          <CampaignManagerGeneralTab
            value={formValue.general}
            onChange={(newFormValue: any) => setFormValue({ ...formValue, general: newFormValue })}
          />
        )}
        {activeTab === "targeting" && (
          <CampaignManagerTargetingTab
            value={formValue.targeting}
            onChange={(newFormValue: any) => setFormValue({ ...formValue, targeting: newFormValue })}
          />
        )}

        {/* @ts-ignore */}
        {id && isAdmin(globalState.user.get().role) && activeTab === "log" && <CampaignManagerLogTab campaignId={id} />}
      </Content>
    </DefaultLayout>
  );
};

export default CampaignManagerPage;

import { useState } from "react";
import { Content, Header, Nav } from "rsuite";
import DefaultLayout from "../../layouts/DefaultLayout";
import RedditTab from "./RedditTab";
import TwitterTab from "./TwitterTab";

const SocialAccountsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState("twitter");

  return (
    <DefaultLayout>
      <Header>
        <h2>Social Accounts</h2>
      </Header>
      <Content>
        <Nav activeKey={activeTab} onSelect={setActiveTab} appearance="subtle" style={{ marginBottom: 20 }}>
          <Nav.Item eventKey="twitter">Twitter</Nav.Item>
          <Nav.Item eventKey="reddit">Reddit</Nav.Item>
        </Nav>

        {activeTab === "twitter" && <TwitterTab />}
        {activeTab === "reddit" && <RedditTab />}
      </Content>
    </DefaultLayout>
  );
};

export default SocialAccountsPage;

import CheckIcon from "@rsuite/icons/Check";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Content, FlexboxGrid, Header, IconButton, Loader, Message, Nav, Schema, toaster } from "rsuite";
import { fetchSystemConfiguration, saveSystemConfiguration } from "../../api/system.api";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getValidationErrorMessages } from "../../lib/form-validation";
import QueueTab from "./QueueTab";
import ScheduleTab from "./ScheduleTab";
import TrafficStreamsTab from "./TrafficStreamsTab";

const defaultFormValue = {
  queue: {
    runningTimeMinutes: "",
    cooldownTimeHours: "",
    domainConcurrency: "",
  },
  trafficStreams: [],
  schedule: [],
};

const SystemConfigurationPage: React.FC = () => {
  const { accountId } = useParams();

  const [activeTab, setActiveTab] = useState("queue");
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [formData, setFormData] = useState(_.cloneDeep(defaultFormValue));
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!accountId) {
        return;
      }

      setLoading(true);
      const data = await fetchSystemConfiguration(accountId);
      setFormData(_.merge(_.cloneDeep(defaultFormValue), data));
      setLoading(false);
    };

    fetch();
  }, []);

  const model = Schema.Model({
    queue: Schema.Types.ObjectType().shape({
      runningTimeMinutes: Schema.Types.NumberType()
        .isRequired("Running time is required")
        .min(1, "Running time must be greater than 0"),
      cooldownTimeHours: Schema.Types.NumberType()
        .isRequired("Cooldown time is required")
        .min(1, "Cooldown time must be greater than 0"),
      domainConcurrency: Schema.Types.NumberType()
        .isRequired("Domain concurrency is required")
        .min(1, "Domain concurrency must be greater than 0"),
    }),
    trafficStreams: Schema.Types.ArrayType().of(
      Schema.Types.ObjectType().shape({
        socialAccountId: Schema.Types.StringType().isRequired("Social account is required"),
        trafficSourceId: Schema.Types.StringType().isRequired("Traffic source is required"),
        targetDesktop: Schema.Types.BooleanType().isRequired("Target desktop is required"),
        targetMobile: Schema.Types.BooleanType().isRequired("Target mobile is required"),
        config: Schema.Types.ObjectType().shape({
          dailyBudget: Schema.Types.NumberType().isRequired("Daily budget is required"),
          totalBudget: Schema.Types.NumberType().isRequired("Total budget is required"),
          bid: Schema.Types.NumberType().isRequired("Bid is required"),
        }),
      })
    ),
  });

  const save = async () => {
    if (!accountId) {
      return;
    }

    const validationResult = model.check(formData);
    const errors = getValidationErrorMessages(validationResult);
    setValidationErrors(errors);
    if (errors.length > 0) {
      return;
    }

    setSaving(true);

    try {
      await saveSystemConfiguration(accountId, formData);
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Configuration saved!
        </Message>
      );
    } catch (err) {
      console.log(err);
    }

    setSaving(false);
  };

  if (loading) {
    return (
      <DefaultLayout>
        <Loader content="Loading..." />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <Header>
        <h2>System Configuration</h2>
      </Header>
      <Content>
        <FlexboxGrid justify="end" style={{ marginBottom: 20 }}>
          <FlexboxGrid.Item>
            <IconButton
              icon={<CheckIcon />}
              appearance="primary"
              color="green"
              loading={saving}
              onClick={() => setShowConfirmDialog(true)}
            >
              Save Configuration
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>

        {validationErrors.length > 0 && (
          <>
            {validationErrors.map((error, i) => (
              <Message key={i} type="error" style={{ marginBottom: 20 }}>
                {error}
              </Message>
            ))}
          </>
        )}

        <Nav activeKey={activeTab} onSelect={setActiveTab} appearance="subtle" style={{ marginBottom: 20 }}>
          <Nav.Item eventKey="queue">Queue</Nav.Item>
          <Nav.Item eventKey="schedule">Schedule</Nav.Item>
          <Nav.Item eventKey="traffic-streams">Traffic Streams</Nav.Item>
        </Nav>

        {activeTab === "queue" && (
          <QueueTab value={formData.queue} onChange={(newValue: any) => setFormData({ ...formData, queue: newValue })} />
        )}

        {activeTab === "schedule" && (
          <ScheduleTab value={formData.schedule} onChange={(newValue: any) => setFormData({ ...formData, schedule: newValue })} />
        )}

        {activeTab === "traffic-streams" && (
          <TrafficStreamsTab
            value={formData.trafficStreams}
            onChange={(newValue: any) => setFormData({ ...formData, trafficStreams: newValue })}
          />
        )}

        <ConfirmDialog
          open={showConfirmDialog ? true : false}
          onCancel={() => {
            setShowConfirmDialog(false);
          }}
          onConfirm={() => {
            save();
            setShowConfirmDialog(false);
          }}
          message="This will cause the current queue to regenerate."
        />
      </Content>
    </DefaultLayout>
  );
};

export default SystemConfigurationPage;

const checkObject = (obj: any) => {
  let messages: string[] = [];

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object") {
      const nestedMessages = checkObject(obj[key]);
      if (nestedMessages.length > 0) {
        messages = messages.concat(nestedMessages);
      }
    }

    if (obj[key]?.hasError) {
      messages.push(obj[key].errorMessage);
    }
  });

  return messages;
};

export const getValidationErrorMessages = (errors: any) => {
  return checkObject(errors).filter((v) => v);
};

import { useEffect, useState } from "react";
import { Message, toaster } from "rsuite";
import { getTrafficSources } from "../api/traffic-sources.api";

export const useTrafficSources = (accountId: string) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const refetch = async () => {
    try {
      setLoading(true);
      const response = await getTrafficSources(accountId);
      setData(response.data);
    } catch (err: any) {
      toaster.push(
        <Message type="error" header="Error" showIcon>
          {err.message}
        </Message>
      );
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    refetch();
  }, []);

  return { data, loading, refetch };
};

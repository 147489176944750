import axios from "axios";
import { toaster, Message } from "rsuite";

export const createApiClient = (token?: string | null) => {
  const clientArgs = {
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {},
    validateStatus: (statusCode: number) => {
      if (statusCode === 401) {
        localStorage.removeItem("token");
        window.location.href = "/auth/login";
        return false;
      }

      if (statusCode === 403) {
        window.location.href = "/";
        return false;
      }

      if (statusCode === 409) {
        toaster.push(
          <Message type="error" header="Error" showIcon>
            Record is not unique
          </Message>
        );
        return false;
      }

      if (statusCode > 300) {
        alert("An unknown error occured, check logs...");
      }

      return statusCode < 400;
    },
  };

  if (token) {
    clientArgs.headers = { Authorization: `Bearer ${token}` };
  } else {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      clientArgs.headers = { Authorization: `Bearer ${storedToken}` };
    }
  }

  const apiClient = axios.create(clientArgs);

  return apiClient;
};

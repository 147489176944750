import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Form, InputPicker } from "rsuite";
import { useAvailableAccounts } from "../lib/use-available-accounts";

// @ts-ignore
const AccountSwitcher = ({ currentAccountId }) => {
  const { data: accounts, loading: accountsLoading } = useAvailableAccounts();
  const currentAccount = accounts?.find((account: any) => account.id === currentAccountId);

  const navigate = useNavigate();

  return (
    <Form.Group style={{ padding: "20px 20px 15px" }}>
      <Form.ControlLabel>Switch Account</Form.ControlLabel>
      <InputPicker
        // @ts-ignore
        value={currentAccount?.id || ""}
        data={accounts.map((account: any) => ({ label: account.name, value: account.id }))}
        onChange={(value) => {
          navigate(`/${value}`);
        }}
        cleanable={false}
      />
    </Form.Group>
  );
};

export default memo(AccountSwitcher);

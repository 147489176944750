import { Form, InputGroup, Input, InputPicker, Panel } from "rsuite";
import { Field } from "../../../components/FormInputs";

const CampaignManagerGeneralTab: React.FC<{ value: any; onChange: any }> = (props) => {
  const { value, onChange } = props;

  return (
    <Panel bordered>
      <Form fluid>
        <Form.Group controlId="targetUrl">
          <Form.ControlLabel>Target URL</Form.ControlLabel>
          <InputGroup style={{ width: "330px" }}>
            <InputGroup.Addon>https://</InputGroup.Addon>
            <Form.Control
              name="targetUrl"
              value={value.targetUrl}
              onChange={(newValue) => onChange({ ...value, targetUrl: newValue.replace("http://", "").replace("https://", "") })}
            />
          </InputGroup>
        </Form.Group>
        <Field
          name="status"
          label="Status"
          value={value.status}
          accepter={InputPicker}
          cleanable={false}
          data={[
            { label: "Active", value: "ACTIVE" },
            { label: "Disabled", value: "DISABLED" },
          ]}
          onChange={(newValue) => onChange({ ...value, status: newValue })}
        />
      </Form>
    </Panel>
  );
};

export default CampaignManagerGeneralTab;

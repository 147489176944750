import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import _ from "lodash";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Content,
  Drawer,
  FlexboxGrid,
  Form,
  Header,
  IconButton,
  InputPicker,
  Message,
  Schema,
  Table,
  toaster,
  Tooltip,
  Whisper,
} from "rsuite";
import { deleteTrafficSource, saveTrafficSource } from "../api/traffic-sources.api";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { Field } from "../components/FormInputs";
import DefaultLayout from "../layouts/DefaultLayout";
import { useTrafficSources } from "../lib/use-traffic-sources";
import { convertKeysToDotNotation } from "../lib/utils";

const { Column, HeaderCell, Cell } = Table;

const defaultFormValue = { source: "", name: "" };

const TrafficSourcesPage: React.FC = () => {
  const { accountId } = useParams();

  const { data, loading, refetch } = useTrafficSources(accountId || "");

  // editorial
  const [editorOpen, setEditorOpen] = useState(false);
  const formRef = useRef();
  const [formError, setFormError] = useState(_.cloneDeep(defaultFormValue));
  const [formValue, setFormValue] = useState(_.cloneDeep(defaultFormValue));

  const model = Schema.Model({
    name: Schema.Types.StringType().isRequired("Please enter a name"),
    source: Schema.Types.StringType().isRequired("Please select a source"),
    config: Schema.Types.ObjectType(),
  });

  const configFields = {
    "ezmob.com": [{ name: "apiToken", label: "API Token" }],
  };

  const submitForm = async () => {
    // @ts-ignore
    if (!formRef?.current.check()) {
      return;
    }

    try {
      // @ts-ignore
      await saveTrafficSource(accountId, formValue);

      setEditorOpen(false);
      refetch();
      setFormValue(_.cloneDeep(defaultFormValue));
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Traffic source saved!
        </Message>
      );
    } catch (err) {
      console.log(err);
    }
  };

  // delete
  const [deleteId, setDeleteId] = useState("");
  const deleteItem = async () => {
    if (!deleteId) {
      return;
    }

    try {
      await deleteTrafficSource(accountId || "", deleteId);
      setDeleteId("");
      refetch();
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Traffic source deleted!
        </Message>
      );
    } catch (err) {
      toaster.push(<Message type="error">Error!!!!</Message>);
      console.log(err);
    }
  };

  // edit
  const onEdit = (rowData: any) => {
    // @ts-ignore
    setFormValue(convertKeysToDotNotation(_.cloneDeep(rowData)));
    setEditorOpen(true);
  };

  return (
    <DefaultLayout>
      <Header>
        <h2>Traffic Sources</h2>
      </Header>
      <Content>
        <FlexboxGrid justify="end" style={{ marginBottom: 20 }}>
          <FlexboxGrid.Item>
            <IconButton
              icon={<PlusIcon />}
              onClick={() => {
                setFormValue(_.cloneDeep(defaultFormValue));
                setEditorOpen(true);
              }}
              appearance="primary"
            >
              Add Traffic Source
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <Drawer size="lg" placement="right" backdrop="static" open={editorOpen} onClose={() => setEditorOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>Traffic Source</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => setEditorOpen(false)}>Cancel</Button>
              <Button onClick={() => submitForm()} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Form
              // @ts-ignore
              ref={formRef}
              onChange={(newValues) => {
                const values = {};
                Object.keys(newValues).forEach((key) => {
                  _.set(values, key, newValues[key]);
                });
                // @ts-ignore
                setFormValue(values);
              }}
              // @ts-ignore
              onCheck={setFormError}
              formValue={formValue}
              model={model}
              fluid
            >
              <Field
                // @ts-ignore
                name="source"
                label="Source"
                accepter={InputPicker}
                error={formError.source}
                data={[{ label: "ezmob.com", value: "ezmob.com" }]}
              />
              <Field
                // @ts-ignore
                name="name"
                label="Name"
                error={formError.name}
              />

              {formValue.source &&
                // @ts-ignore
                configFields[formValue.source]?.map((field) => (
                  // @ts-ignore
                  <Field key={field.name} name={`config.${field.name}`} label={field.label} required />
                ))}
            </Form>
          </Drawer.Body>
        </Drawer>

        <Table
          data={data}
          loading={loading}
          onRowClick={(rowData, e) => {
            // @ts-ignore
            if (["svg", "path", "button"].includes(e.target.tagName.toLowerCase())) {
              return;
            }
            onEdit(rowData);
          }}
          rowClassName="cursor-pointer"
          autoHeight
          bordered
        >
          <Column>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Source</HeaderCell>
            <Cell dataKey="source" />
          </Column>

          <Column width={200}>
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <ButtonToolbar>
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Delete</Tooltip>}>
                    <IconButton
                      size="xs"
                      icon={<TrashIcon />}
                      appearance="primary"
                      color="red"
                      onClick={() => setDeleteId(rowData.id)}
                    />
                  </Whisper>
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </Table>

        <ConfirmDialog
          open={deleteId ? true : false}
          onCancel={() => {
            setDeleteId("");
          }}
          onConfirm={() => deleteItem()}
        />
      </Content>
    </DefaultLayout>
  );
};

export default TrafficSourcesPage;

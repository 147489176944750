import { Content, IconButton } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import { useState } from "react";
import _ from "lodash";

const defaultFormValue = {};

const RedditTab: React.FC = () => {
  // editorial
  const [editorOpen, setEditorOpen] = useState(false);
  const [formValue, setFormValue] = useState(_.cloneDeep(defaultFormValue));

  return (
    <Content>
      <IconButton
        icon={<PlusIcon />}
        onClick={() => {
          setFormValue(_.cloneDeep(defaultFormValue));
          setEditorOpen(true);
        }}
        appearance="primary"
        color="red"
      >
        Add Reddit Account
      </IconButton>
    </Content>
  );
};

export default RedditTab;

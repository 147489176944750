import { hookstate, useHookstate } from "@hookstate/core";

const globalState = hookstate({
  user: {
    id: null,
    username: null,
    role: null,
  },
});

export const useGlobalState = () => useHookstate(globalState);

import { Form, InputGroup, Panel, Tooltip, Whisper } from "rsuite";

const QueueTab: React.FC<{ value: any; onChange: any }> = (props) => {
  const { value, onChange } = props;

  return (
    <Panel bordered>
      <Form fluid>
        <Form.Group controlId="runningTimeMinutes">
          <Form.ControlLabel>Running Time</Form.ControlLabel>

          <Whisper trigger="focus" speaker={<Tooltip>Duration that a campaign should run for at a given time.</Tooltip>}>
            <InputGroup style={{ width: "330px" }}>
              <Form.Control
                name="runningTimeMinutes"
                type="number"
                value={value?.runningTimeMinutes?.toString() || ""}
                onChange={(newValue: any) => onChange({ ...value, runningTimeMinutes: parseInt(newValue) })}
              />
              <InputGroup.Addon>Minutes</InputGroup.Addon>
            </InputGroup>
          </Whisper>
        </Form.Group>

        <Form.Group controlId="cooldownTimeHours">
          <Form.ControlLabel>Cooldown Time</Form.ControlLabel>

          <Whisper
            trigger="focus"
            speaker={<Tooltip>Amount of time that should pass before a campaign can re-enter the queue.</Tooltip>}
          >
            <InputGroup style={{ width: "330px" }}>
              <Form.Control
                name="cooldownTimeHours"
                type="number"
                value={value?.cooldownTimeHours?.toString() || ""}
                onChange={(newValue: any) => onChange({ ...value, cooldownTimeHours: parseInt(newValue) })}
                help="The time between each queue run"
              />
              <InputGroup.Addon>Hours</InputGroup.Addon>
            </InputGroup>
          </Whisper>
        </Form.Group>

        <Form.Group controlId="domainConcurrency">
          <Form.ControlLabel>Domain Concurrency</Form.ControlLabel>

          <Whisper
            trigger="focus"
            speaker={<Tooltip>Number of campaign target URLs of a given domain that can execute at the same time.</Tooltip>}
          >
            <InputGroup style={{ width: "330px" }}>
              <Form.Control
                name="domainConcurrency"
                type="number"
                value={value?.domainConcurrency?.toString() || ""}
                onChange={(newValue: any) => onChange({ ...value, domainConcurrency: parseInt(newValue) })}
              />
            </InputGroup>
          </Whisper>
        </Form.Group>
      </Form>
    </Panel>
  );
};

export default QueueTab;

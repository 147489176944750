import { createApiClient } from "./client";

export const getUserByToken = async (token?: string | null) => {
  const client = createApiClient(token);
  const user = await client.get("/auth/me");
  return user;
};

export const loginWithBasicAuth = async (username: string, password: string) => {
  const client = createApiClient();
  const { data } = await client.get("/auth/login", { auth: { username, password } });
  return data;
};

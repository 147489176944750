import { Form, IconButton, InputPicker, List, Panel, Stack, Toggle, Tooltip, Whisper } from "rsuite";
import { Field } from "../../components/FormInputs";
import PlusIcon from "@rsuite/icons/Plus";
import _ from "lodash";
import { useTrafficSources } from "../../lib/use-traffic-sources";
import { useSocialAccounts } from "../../lib/use-social-accounts";
import { useParams } from "react-router-dom";

export const trafficStreamStatusColors = {
  APPROVED: "mediumseagreen",
  AWAITING_INITIALISATION: "orange",
  PENDING_APPROVAL: "deepskyblue",
  REJECTED: "indianred",
};

const emptyStream = {
  socialAccountId: "",
  trafficSourceId: "",
  targetDesktop: false,
  targetMobile: false,
  config: {},
};

const configFields = {
  "ezmob.com": [
    { label: "Daily Budget", key: "dailyBudget" },
    { label: "Total Budget", key: "totalBudget" },
    { label: "Bid", key: "bid" },
  ],
};

const TrafficStreamsTab: React.FC<{ value: any; onChange: any }> = (props) => {
  const { value, onChange } = props;

  const { accountId = "" } = useParams();

  const { data: trafficSources } = useTrafficSources(accountId);
  const { data: socialAccounts } = useSocialAccounts({ accountId });

  const addStream = () => {
    const streams = [..._.cloneDeep(value), _.cloneDeep(emptyStream)];
    onChange(streams);
  };

  const updateStream = (index: number, field: string, newValue: string) => {
    const streams = _.cloneDeep(value);
    _.set(streams, `[${index}].${field}`, newValue);
    onChange(streams);
  };

  const removeStream = (index: number) => {
    const streams = _.cloneDeep(value);
    streams.splice(index, 1);
    onChange(streams);
  };

  return (
    <Panel bordered>
      <Form fluid>
        <IconButton icon={<PlusIcon />} appearance="primary" onClick={() => addStream()}>
          Add Stream
        </IconButton>

        <List size="lg" style={{ marginTop: 20 }}>
          {value.map((stream: any, index: number) => (
            <List.Item key={index}>
              <h5 style={{ marginBottom: 5 }}>
                Stream {index + 1}{" "}
                <IconButton
                  icon={<PlusIcon />}
                  appearance="primary"
                  color="red"
                  size="xs"
                  onClick={() => removeStream(index)}
                  style={{ marginLeft: 20 }}
                >
                  Remove Stream
                </IconButton>
              </h5>

              <Stack spacing={20}>
                {stream.status && (
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{stream.status}</Tooltip>}>
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        marginTop: 23,
                        // @ts-ignore
                        background: trafficStreamStatusColors[stream.status],
                        borderRadius: "100%",
                      }}
                    />
                  </Whisper>
                )}

                <Field
                  name="socialAccountId"
                  label="Social Account"
                  value={stream.socialAccountId}
                  accepter={InputPicker}
                  data={socialAccounts.map((account: any) => ({
                    label: `${account.name} - [${account.provider}]`,
                    value: account.id,
                  }))}
                  onChange={(newValue) => updateStream(index, "socialAccountId", newValue)}
                />

                <Field
                  name="trafficSourceId"
                  label="Traffic Source"
                  value={stream.trafficSourceId}
                  accepter={InputPicker}
                  data={trafficSources.map((source: any) => ({ label: `${source.name} - [${source.source}]`, value: source.id }))}
                  onChange={(newValue) => updateStream(index, "trafficSourceId", newValue)}
                />

                {/* @ts-ignore */}
                {configFields?.[trafficSources?.find((v) => v.id === stream.trafficSourceId)?.source]?.map((field) => (
                  <Field
                    key={field.key}
                    name={`config.${field.key}`}
                    label={field.label}
                    value={stream.config[field.key]}
                    onChange={(newValue) => updateStream(index, `config.${field.key}`, newValue)}
                  />
                ))}

                <Field
                  name="targetDesktop"
                  label="Desktop"
                  accepter={Toggle}
                  value={stream.targetDesktop}
                  checked={stream.targetDesktop}
                  onChange={(newValue) => updateStream(index, "targetDesktop", newValue)}
                />

                <Field
                  name="targetMobile"
                  label="Mobile"
                  accepter={Toggle}
                  value={stream.targetMobile}
                  checked={stream.targetMobile}
                  onChange={(newValue) => updateStream(index, "targetMobile", newValue)}
                />
              </Stack>
            </List.Item>
          ))}
        </List>
      </Form>
    </Panel>
  );
};

export default TrafficStreamsTab;

import ReloadIcon from "@rsuite/icons/Reload";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Content,
  FlexboxGrid,
  Header,
  IconButton,
  Message,
  Pagination,
  Progress,
  Table,
  toaster,
  Tooltip,
  Whisper,
} from "rsuite";
import { getQueue, regenerateQueue as commitRegenerateQueue } from "../api/queue.api";
import DefaultLayout from "../layouts/DefaultLayout";
import { isAdmin } from "../lib/utils";
import { useGlobalState } from "../state";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import * as _ from "lodash";
import { ConfirmDialog } from "../components/ConfirmDialog";
import AppSelectIcon from "@rsuite/icons/AppSelect";

const { Column, HeaderCell, Cell } = Table;

export const QUEUE_STATUS_COLORS = {
  RUNNING: "deepskyblue",
  WAITING: "orange",
  STARTING: "deepskyblue",
  COMPLETE: "mediumseagreen",
  FAILED: "indianred",
};

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }: any) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      icon={expandedRowKeys.some((key: any) => key === rowData.id) ? <CollaspedOutlineIcon /> : <ExpandOutlineIcon />}
    />
  </Cell>
);

const renderRowExpanded = (rowData: any) => {
  return <div>Target Countries: {rowData.campaign.targetCountries.join(", ")}</div>;
};

const QueuePage: React.FC = () => {
  const globalState = useGlobalState();

  const { accountId = "" } = useParams();

  const [loading, setLoading] = useState(false);
  const [regenerating, setRegenerating] = useState(false);
  const [queue, setQueue] = useState([]);
  const [showConfirmRegenerate, setShowConfirmRegenerate] = useState(false);

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchQueue = async () => {
    setLoading(true);
    const skip = (page - 1) * limit;
    const resp = await getQueue(accountId, limit, skip);
    setQueue(resp.data);
    setTotal(resp.pagination.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchQueue();
  }, [page, limit]);

  const regenerateQueue = async () => {
    setRegenerating(true);
    await commitRegenerateQueue(accountId);
    setRegenerating(false);
    toaster.push(
      <Message type="success" header="Success" showIcon>
        Queue Regenerated Successfully
      </Message>
    );

    fetchQueue();
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: any = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData.id) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData.id);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const handleChangeLimit = (dataKey: number) => {
    setPage(1);
    setLimit(dataKey);
  };

  return (
    <DefaultLayout>
      <Header>
        <h2>Queue</h2>
      </Header>
      <Content>
        {/* @ts-ignore */}
        {isAdmin(globalState.user.get().role) && (
          <FlexboxGrid justify="end" style={{ marginBottom: 20 }}>
            <FlexboxGrid.Item>
              <Whisper
                placement="bottom"
                trigger="hover"
                speaker={<Tooltip>This will clear all queued items and rebuild the queue from active campaigns.</Tooltip>}
              >
                <IconButton
                  icon={<AppSelectIcon />}
                  appearance="primary"
                  disabled={regenerating || loading}
                  loading={regenerating}
                  onClick={() => setShowConfirmRegenerate(true)}
                >
                  Regenerate Queue
                </IconButton>
              </Whisper>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item style={{ marginLeft: 20 }}>
              <IconButton
                icon={<ReloadIcon />}
                appearance="primary"
                color="green"
                disabled={regenerating || loading}
                loading={loading}
                onClick={() => fetchQueue()}
              >
                Refresh Status
              </IconButton>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        )}

        <Table
          loading={loading}
          data={queue}
          rowKey="id"
          expandedRowKeys={expandedRowKeys}
          renderRowExpanded={renderRowExpanded}
          autoHeight
          bordered
        >
          <Column width={70} align="center">
            <HeaderCell>#</HeaderCell>
            <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
          </Column>

          <Column flexGrow={2}>
            <HeaderCell>Campaign</HeaderCell>
            <Cell dataKey="campaign.targetUrl" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Ready Time</HeaderCell>
            <Cell>{(rowData) => new Date(rowData.nextRunTime).toLocaleString()}</Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Started At</HeaderCell>
            <Cell>{(rowData) => (rowData.startTime ? new Date(rowData.startTime).toLocaleString() : "-")}</Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Completed At</HeaderCell>
            <Cell>{(rowData) => (rowData.endTime ? new Date(rowData.endTime).toLocaleString() : "-")}</Cell>
          </Column>

          <Column width={100} align="center">
            <HeaderCell>Status</HeaderCell>
            <Cell>
              {(rowData) => (
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={<Tooltip>{_.startCase(rowData.status.toLowerCase())}</Tooltip>}
                >
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: "auto",
                      marginRight: "auto",
                      // @ts-ignore
                      background: QUEUE_STATUS_COLORS[rowData.status],
                      borderRadius: "100%",
                    }}
                  />
                </Whisper>
              )}
            </Cell>
          </Column>
        </Table>

        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager"]}
            total={total}
            limitOptions={[10, 25, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        <ConfirmDialog
          open={showConfirmRegenerate ? true : false}
          onCancel={() => {
            setShowConfirmRegenerate(false);
          }}
          onConfirm={() => {
            regenerateQueue();
            setShowConfirmRegenerate(false);
          }}
          message="This will clear all queued items and rebuild the queue from active campaigns."
        />
      </Content>
    </DefaultLayout>
  );
};

export default QueuePage;

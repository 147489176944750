import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ButtonToolbar, Content, IconButton, Message, Table, toaster, Tooltip, Whisper } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { deleteSocialAccount, getTwitterLoginUrl } from "../../api/social.api";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { useSocialAccounts } from "../../lib/use-social-accounts";

const TwitterTab: React.FC = () => {
  const { accountId = "" } = useParams();

  const { data, refetch } = useSocialAccounts({ accountId, provider: "TWITTER" });
  const [loading, setLoading] = useState(false);

  const addAccount = async () => {
    setLoading(true);
    const url = await getTwitterLoginUrl(accountId);
    window.location.href = url;
  };

  // delete
  const [deleteId, setDeleteId] = useState("");
  const deleteItem = async () => {
    if (!deleteId) {
      return;
    }

    try {
      await deleteSocialAccount(accountId, deleteId);
      setDeleteId("");
      refetch();
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Social account deleted!
        </Message>
      );
    } catch (err) {
      toaster.push(<Message type="error">Error!!!!</Message>);
      console.log(err);
    }
  };

  return (
    <Content>
      <IconButton icon={<PlusIcon />} appearance="primary" color="blue" loading={loading} onClick={addAccount}>
        Add Twitter Account
      </IconButton>

      <Table data={data} loading={loading} autoHeight bordered style={{ marginTop: 20 }}>
        <Column>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell dataKey="status" />
        </Column>

        <Column width={200}>
          <HeaderCell>Actions</HeaderCell>
          <Cell>
            {(rowData) => (
              <ButtonToolbar>
                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Delete</Tooltip>}>
                  <IconButton
                    size="xs"
                    icon={<TrashIcon />}
                    appearance="primary"
                    color="red"
                    onClick={() => setDeleteId(rowData.id)}
                  />
                </Whisper>
              </ButtonToolbar>
            )}
          </Cell>
        </Column>
      </Table>

      <ConfirmDialog
        open={deleteId ? true : false}
        onCancel={() => {
          setDeleteId("");
        }}
        onConfirm={() => deleteItem()}
      />
    </Content>
  );
};

export default TwitterTab;

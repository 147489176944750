import { useEffect, useState } from "react";
import { Message, toaster } from "rsuite";
import { getUserByToken } from "../api/auth.api";

export const useAvailableAccounts = () => {
  const storedAvailableAccounts = localStorage.getItem("availableAccounts");

  const [data, setData] = useState(storedAvailableAccounts ? JSON.parse(storedAvailableAccounts) : []);
  const [loading, setLoading] = useState(false);

  const refetch = async () => {
    if (storedAvailableAccounts) {
      if (JSON.stringify(data) === storedAvailableAccounts) {
        setLoading(false);
        return;
      }
      setData(JSON.parse(storedAvailableAccounts));
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const { data: resp } = await getUserByToken(localStorage.getItem("token"));
      setData(resp?.accounts || []);
    } catch (err: any) {
      toaster.push(
        <Message type="error" header="Error" showIcon>
          {err.message}
        </Message>
      );
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    refetch();
  }, []);

  return { data, loading, refetch };
};

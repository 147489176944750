import { useNavigate } from "react-router-dom";
import { Content, Dropdown, Header, Loader, Message, toaster } from "rsuite";
import DefaultLayout from "../layouts/DefaultLayout";
import { useAvailableAccounts } from "../lib/use-available-accounts";

const SelectAccountPage: React.FC = () => {
  const { data: accounts, loading } = useAvailableAccounts();
  const navigate = useNavigate();

  if (window.location.search.includes("twitterCallbackError=true")) {
    toaster.push(
      <Message type="error" header="Error" showIcon>
        Something went wrong - is the social account already in use?
      </Message>
    );
  } else if (window.location.search.includes("twitterCallbackSuccess=true")) {
    toaster.push(
      <Message type="success" header="Success" showIcon>
        Twitter account added successfully.
      </Message>
    );
  }

  return (
    <DefaultLayout>
      <Header>
        <h2>Select Account</h2>
      </Header>
      <Content>
        {loading && <Loader />}

        {!loading && accounts.length > 0 && (
          <Dropdown title="Select an account" onSelect={(eventKey: string) => navigate(`/${eventKey}`)}>
            {accounts.map((account: any) => (
              <Dropdown.Item key={account.id} eventKey={account.id}>
                {account.name}
              </Dropdown.Item>
            ))}
          </Dropdown>
        )}
      </Content>
    </DefaultLayout>
  );
};

export default SelectAccountPage;

import { createApiClient } from "./client";

export const getTwitterLoginUrl = async (accountId: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/auth/social/twitter/${accountId}/login-url`);
  return data.data;
};

export const getSocialAccounts = async ({ accountId, provider }: { accountId: string; provider: string }) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/social-accounts${provider ? `?provider=${provider}` : ""}`);
  return data;
};

export const deleteSocialAccount = async (accountId: string, id: string) => {
  const client = createApiClient();
  await client.delete(`/${accountId}/social-accounts/${id}`);
  return true;
};

import _ from "lodash";
import { createApiClient } from "./client";

export const getQueue = async (accountId: string, take: number = 25, skip: number = 0) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/queue?take=${take}&skip=${skip}`);
  return data;
};

export const regenerateQueue = async (accountId: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/queue/regenerate`);
  return data;
};

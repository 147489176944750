import React from "react";
import { Form } from "rsuite";

export const Field = React.forwardRef(
  (
    props: {
      name: string;
      label: string;
      value?: any;
      onChange?: (value: any) => void;
      message?: string;
      error?: any;
      accepter?: any;
      data?: any;
      placeholder?: string;
      cleanable?: boolean;
      block?: boolean;
      type?: string;
      checked?: boolean;
      style?: any;
    },
    ref
  ) => {
    // @ts-ignore
    const { name, message, label, accepter, error, ...rest } = props;

    return (
      // @ts-ignore
      <Form.Group controlId={name} ref={ref} className={error ? "has-error" : ""}>
        <Form.ControlLabel>{label} </Form.ControlLabel>
        <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
        {message && <Form.HelpText>{message}</Form.HelpText>}
      </Form.Group>
    );
  }
);

import { Content, Header } from "rsuite";
import DefaultLayout from "../layouts/DefaultLayout";
import { useGlobalState } from "../state";

const DashboardPage: React.FC = () => {
  const globalState = useGlobalState();

  return (
    <DefaultLayout>
      <Header>
        <h2>Dashboard</h2>
      </Header>
      <Content>
        {/* @ts-ignore */}
        <p>Hello {globalState?.user?.get()?.username},</p>
        <p>I have nothing to show you right now :(</p>
      </Content>
    </DefaultLayout>
  );
};

export default DashboardPage;

import { Form, Panel, TagPicker } from "rsuite";
import { Field } from "../../../components/FormInputs";
import countries from "../../../lib/countries";

const CampaignManagerTargetingTab: React.FC<{ value: any; onChange: any }> = (props) => {
  const { value, onChange } = props;

  return (
    <Panel bordered>
      <Form fluid>
        <Field
          name="countries"
          label="Countries"
          value={value.countries}
          accepter={TagPicker}
          cleanable={true}
          data={countries.map((country) => ({ label: country.name, value: country.code }))}
          onChange={(newValue) => onChange({ ...value, countries: newValue })}
          block
        />
      </Form>
    </Panel>
  );
};

export default CampaignManagerTargetingTab;

import { Header } from "rsuite";
import DefaultLayout from "../layouts/DefaultLayout";


const NotFoundPage: React.FC = () => {
  return (
    <DefaultLayout>
      <Header>
        <h2>Page not found.</h2>
      </Header>
    </DefaultLayout>
  );
};

export default NotFoundPage;

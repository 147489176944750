import PlusIcon from "@rsuite/icons/Plus";
import _ from "lodash";
import { Form, IconButton, List, Panel, Stack } from "rsuite";
import { Field } from "../../components/FormInputs";

const emptyItem = {
  runCountMin: "",
  daysToWait: "",
};

const ScheduleTab: React.FC<{ value: any; onChange: any }> = (props) => {
  const { value, onChange } = props;

  const addItem = () => {
    const items = [..._.cloneDeep(value), _.cloneDeep(emptyItem)];
    onChange(items);
  };

  const updateItem = (index: number, field: string, newValue: string) => {
    const items = _.cloneDeep(value);
    _.set(items, `[${index}].${field}`, typeof newValue === "number" ? newValue : parseInt(newValue));
    onChange(items);
  };

  const removeItem = (index: number) => {
    const items = _.cloneDeep(value);
    items.splice(index, 1);
    onChange(items);
  };

  return (
    <Panel bordered>
      <Form fluid>
        <IconButton icon={<PlusIcon />} appearance="primary" onClick={() => addItem()}>
          Add Schedule Item
        </IconButton>

        <List size="lg" style={{ marginTop: 20 }}>
          {value.map((item: any, index: number) => (
            <List.Item key={index}>
              <h5 style={{ marginBottom: 5 }}>
                Item {index + 1}{" "}
                <IconButton
                  icon={<PlusIcon />}
                  appearance="primary"
                  color="red"
                  size="xs"
                  onClick={() => removeItem(index)}
                  style={{ marginLeft: 20 }}
                >
                  Remove Item
                </IconButton>
              </h5>

              <Stack spacing={20}>
                <Field
                  name="runCountMin"
                  label="Min. Run Count"
                  type="number"
                  value={item.runCountMin?.toString() || ""}
                  onChange={(newValue: any) => updateItem(index, "runCountMin", newValue)}
                />

                <Field
                  name="daysToWait"
                  label="Number of Days to Wait Before Running Again"
                  type="number"
                  value={item.daysToWait?.toString() || ""}
                  onChange={(newValue: any) => updateItem(index, "daysToWait", newValue)}
                />
              </Stack>
            </List.Item>
          ))}
        </List>
      </Form>
    </Panel>
  );
};

export default ScheduleTab;

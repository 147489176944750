import DashboardIcon from "@rsuite/icons/Dashboard";
import ExitIcon from "@rsuite/icons/Exit";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import SpeakerIcon from "@rsuite/icons/Speaker";
import WaitIcon from "@rsuite/icons/Wait";
import React, { PropsWithChildren, useState } from "react";
import Helmet from "react-helmet";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import { Container, Form, InputPicker, Loader, Nav, Navbar, Sidebar, Sidenav } from "rsuite";
import AccountSwitcher from "../components/AccountSwitcher";
import { useAvailableAccounts } from "../lib/use-available-accounts";
import { useGlobalState } from "../state";

// @ts-ignore
const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
  // @ts-ignore
  <Link ref={ref} to={href} {...rest}>
    {children}
  </Link>
));

const DefaultLayout: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const globalState = useGlobalState();

  const { accountId } = useParams();

  const navigate = useNavigate();

  const [activeKey, setActiveKey] = useState("");
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  const [expanded, setExpand] = useState(true);

  const logout = () => {
    localStorage.removeItem("availableAccounts");
    localStorage.removeItem("token");
    globalState.user.set({ id: null, username: null, role: null });
    window.location.href = "/auth/login";
  };

  const isMatchedUrl = (path: string) => {
    const match = useMatch(path);
    return match ? true : false;
  };

  const navItems = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      href: `/${accountId}`,
      isActive: isMatchedUrl(`/${accountId}`),
    },
    {
      label: "Campaigns",
      icon: <SpeakerIcon />,
      href: `/${accountId}/campaigns`,
      isActive: isMatchedUrl(`/${accountId}/campaigns/*`),
    },
    {
      label: "Queue",
      icon: <WaitIcon />,
      href: `/${accountId}/queue`,
      isActive: isMatchedUrl(`/${accountId}/queue/*`),
    },
    {
      label: "Settings",
      icon: <GearCircleIcon />,
      eventKey: "settings",
      isActive: false,
      permittedRoles: ["ADMIN", "SUPER_ADMIN"],
      children: [
        {
          label: "Traffic Sources",
          href: `/${accountId}/traffic-sources`,
          isActive: isMatchedUrl(`/${accountId}/traffic-sources`),
        },
        {
          label: "Social Accounts",
          href: `/${accountId}/social-accounts`,
          isActive: isMatchedUrl(`/${accountId}/social-accounts`),
        },
        {
          label: "System Configuration",
          href: `/${accountId}/system-configuration`,
          isActive: isMatchedUrl(`/${accountId}/system-configuration`),
        },
        {
          label: "Users",
          href: `/${accountId}/users`,
          isActive: isMatchedUrl(`/${accountId}/users`),
        },
      ],
    },
  ];

  // set active key
  navItems.forEach((navItem) => {
    if (activeKey) {
      return;
    }

    if (navItem.isActive) {
      setActiveKey(navItem.href || navItem.eventKey || "");
      return;
    }

    if (navItem.children) {
      navItem.children.forEach((child) => {
        if (child.isActive) {
          setActiveKey(child.href);
          // @ts-ignore
          setDefaultOpenKeys([navItem.href || navItem.eventKey || ""]);
          return;
        }
      });
    }
  });

  return (
    <Container>
      <Helmet>
        <title>RNKR.IO</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap" rel="stylesheet" />
      </Helmet>

      <Sidebar style={{ display: "flex", flexDirection: "column" }} width={expanded ? 260 : 56} collapsible>
        <Sidenav.Header>
          <div
            style={{
              background: "var(--rs-primary-500)",
              textAlign: expanded ? "left" : "center",
              padding: 16,
              color: " #fff",
              fontWeight: 600,
              fontSize: 20,
              height: 56,
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <img
              src="/rocket-2.png"
              alt=""
              height={30}
              style={{ marginRight: expanded ? 12 : 0, marginLeft: expanded ? 0 : -4 }}
            />
            {expanded ? "RNKR.IO" : ""}
          </div>
        </Sidenav.Header>

        {expanded && <AccountSwitcher currentAccountId={accountId} />}

        <Sidenav expanded={expanded} defaultOpenKeys={defaultOpenKeys} appearance="subtle">
          <Sidenav.Body>
            {accountId && (
              <Nav activeKey={activeKey}>
                {navItems
                  .filter((navItem) => {
                    // @ts-ignore
                    return !navItem.permittedRoles || navItem.permittedRoles.includes(globalState.user.get()?.role);
                  })
                  .map((navItem, i) => {
                    if (navItem.children) {
                      return (
                        <Nav.Menu key={i} title="Settings" icon={<GearCircleIcon />} eventKey="settings">
                          {navItem.children.map((child, ii) => (
                            <Nav.Item key={ii} as={NavLink} href={child.href || "#"} eventKey={child.href}>
                              {child.label}
                            </Nav.Item>
                          ))}
                        </Nav.Menu>
                      );
                    }

                    return (
                      <Nav.Item
                        key={i}
                        as={NavLink}
                        href={navItem.href || "#"}
                        eventKey={navItem.eventKey || navItem.href}
                        icon={navItem.icon}
                      >
                        {navItem.label}
                      </Nav.Item>
                    );
                  })}

                <Nav.Item as={NavLink} href="#" icon={<ExitIcon />} onClick={() => logout()}>
                  Log out
                </Nav.Item>
              </Nav>
            )}
          </Sidenav.Body>
          <Sidenav.Toggle onToggle={setExpand} />
        </Sidenav>
      </Sidebar>

      <Container>
        <div style={{ padding: "20px 30px" }}>{children}</div>
      </Container>
    </Container>
  );
};

export default DefaultLayout;

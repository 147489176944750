import { createApiClient } from "./client";

export const fetchSystemConfiguration = async (accountId: string) => {
  const client = createApiClient();
  const { data } = await client.get(`${accountId}/settings/system-configuration`);
  return data.data;
};

export const saveSystemConfiguration = async (accountId: string, dto: any) => {
  const client = createApiClient();
  const { data } = await client.patch(`${accountId}/settings/system-configuration`, dto);
  return data;
};

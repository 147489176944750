import { Button, Modal } from "rsuite";

export const ConfirmDialog: React.FC<{ open: boolean; onCancel: any; onConfirm: any; message?: string }> = ({
  open,
  onCancel,
  onConfirm,
  message,
}) => {
  return (
    <Modal backdrop="static" role="alertdialog" open={open} onClose={onCancel} size="xs">
      <Modal.Body>{message || "This action is irreversible, are you sure you wish to continue?"}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} appearance="subtle">
          Cancel
        </Button>
        <Button onClick={onConfirm} appearance="primary">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

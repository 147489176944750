import _ from "lodash";
import { createApiClient } from "./client";

export const getUsers = async (accountId: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/users`);
  return data;
};

export const getOneUser = async (accountId: string, id: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/users/${id}`);
  return data;
};

export const createUser = async (accountId: string, dto: any) => {
  const client = createApiClient();
  const { data } = await client.post(`/${accountId}/users`, dto);
  return data;
};

export const updateUser = async (accountId: string, id: string, dto: any) => {
  const client = createApiClient();
  const { data } = await client.patch(`/${accountId}/users/${id}`, dto);
  return data;
};

export const saveUser = async (accountId: string, data: any) => {
  if (data?.id) {
    const result = await updateUser(accountId, data.id, _.omit(data, ["id"]));
    return result;
  }

  const result = await createUser(accountId, data);
  return result;
};

export const deleteUser = async (accountId: string, id: string) => {
  const client = createApiClient();
  await client.delete(`/${accountId}/users/${id}`);
  return true;
};

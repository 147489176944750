import "./assets/styles.css";

import { FunctionComponent, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import DashboardPage from "./pages/Dashboard";
import LoginPage from "./pages/Login";
import { getUserByToken } from "./api/auth.api";
import TrafficSourcesPage from "./pages/TrafficSources";
import SocialAccountsPage from "./pages/social-accounts/SocialAccounts";
import { Col, Container, Content, FlexboxGrid, Loader, Panel } from "rsuite";
import CampaignsPage from "./pages/campaigns/Campaigns";
import CampaignManagerPage from "./pages/campaigns/CampaignManager";
import SystemConfigurationPage from "./pages/system-configuration/SystemConfiguration";
import { useGlobalState } from "./state";
import NotFoundPage from "./pages/NotFound";
import QueuePage from "./pages/Queue";
import SelectAccountPage from "./pages/SelectAccount";
import UsersPage from "./pages/Users";

const PrivateRoute: React.FC<{ component: FunctionComponent }> = ({ component: Component, ...rest }) => {
  const globalState = useGlobalState();

  const isAuthorised = true;
  const token = localStorage.getItem("token");

  if (token && isAuthorised) {
    const { accountId } = useParams();
    const { pathname } = useLocation();
    if (!accountId && pathname !== "/select-account") {
      return <Navigate to={{ pathname: "/select-account" }} />;
    }

    return <Component {...rest} />;
  }

  localStorage.removeItem("availableAccounts");
  localStorage.removeItem("token");
  globalState.user.set({ id: null, username: null, role: null });

  return <Navigate to={{ pathname: "/auth/login" }} />;
};

export default function App() {
  const accountId = window.location.pathname.split("/")?.[1];
  const globalState = useGlobalState();
  const [authLoading, setAuthLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      return;
    }

    async function verifyToken() {
      const { data: resp } = await getUserByToken(token);
      if (accountId && accountId !== "select-account") {
        const account = resp?.accounts?.find((account: any) => account.id === accountId);
        localStorage.setItem("availableAccounts", JSON.stringify(resp?.accounts || []));
        if (!account) {
          window.location.href = "/select-account";
          return false;
        }
      }

      globalState.user.set(resp.user);
      setAuthLoading(false);
    }

    verifyToken();
  }, [token]);

  if (authLoading && token) {
    return (
      <Container>
        <Content>
          <FlexboxGrid justify="center" style={{ marginTop: 40 }}>
            <FlexboxGrid.Item as={Col} colspan={24} md={10} lg={8}>
              <Panel style={{ textAlign: "center" }}>
                <Loader
                  size="md"
                  speed="slow"
                  content={<h4 style={{ margin: "0 0 0 10px" }}>Verifying your credentials...</h4>}
                />
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/:accountId/system-configuration" element={<SystemConfigurationPage />} />
        <Route path="/:accountId/social-accounts" element={<PrivateRoute component={SocialAccountsPage} />} />
        <Route path="/:accountId/traffic-sources" element={<PrivateRoute component={TrafficSourcesPage} />} />
        <Route path="/:accountId/campaigns/manager/:id" element={<PrivateRoute component={CampaignManagerPage} />} />
        <Route path="/:accountId/campaigns/manager" element={<PrivateRoute component={CampaignManagerPage} />} />
        <Route path="/:accountId/campaigns" element={<PrivateRoute component={CampaignsPage} />} />
        <Route path="/:accountId/queue" element={<PrivateRoute component={QueuePage} />} />
        <Route path="/:accountId/users" element={<PrivateRoute component={UsersPage} />} />
        <Route path="/:accountId" element={<PrivateRoute component={DashboardPage} />} />
        <Route path="/select-account" element={<PrivateRoute component={SelectAccountPage} />} />
        <Route path="/" element={<PrivateRoute component={DashboardPage} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Content,
  Drawer,
  FlexboxGrid,
  Form,
  Header,
  IconButton,
  InputPicker,
  Loader,
  Message,
  Table,
  TagPicker,
  toaster,
  Tooltip,
  Whisper,
} from "rsuite";
import { deleteUser, getUsers, saveUser } from "../api/users.api";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { Field } from "../components/FormInputs";
import DefaultLayout from "../layouts/DefaultLayout";
import { useAvailableAccounts } from "../lib/use-available-accounts";
import { convertKeysToDotNotation } from "../lib/utils";

const { Column, HeaderCell, Cell } = Table;

const defaultFormValue = { id: null, username: "", role: "", newPassword: null, accountIds: [] };

const UsersPage: React.FC = () => {
  const { accountId = "" } = useParams();

  const { data: accounts, loading: accountsLoading } = useAvailableAccounts();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    const { data } = await getUsers(accountId);
    setUsers(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // editorial
  const [editorOpen, setEditorOpen] = useState(false);
  const [formValue, setFormValue] = useState(_.cloneDeep(defaultFormValue));

  const submitForm = async () => {
    if (!formValue.id && !formValue.newPassword) {
      toaster.push(<Message type="error">Please enter a password</Message>);
      return;
    }

    try {
      // @ts-ignore
      await saveUser(accountId, formValue);

      setEditorOpen(false);
      fetchUsers();
      setFormValue(_.cloneDeep(defaultFormValue));
      toaster.push(
        <Message type="success" header="Success" showIcon>
          User saved!
        </Message>
      );
    } catch (err) {
      console.log(err);
    }
  };

  // delete
  const [deleteId, setDeleteId] = useState("");
  const deleteItem = async () => {
    if (!deleteId) {
      return;
    }

    try {
      await deleteUser(accountId, deleteId);
      setDeleteId("");
      fetchUsers();
      toaster.push(
        <Message type="success" header="Success" showIcon>
          User deleted!
        </Message>
      );
    } catch (err) {
      toaster.push(<Message type="error">Error!!!!</Message>);
      console.log(err);
    }
  };

  // edit
  const onEdit = (rowData: any) => {
    const formData = _.cloneDeep(rowData);
    formData.accountIds = formData.accounts.map((account: any) => account.id);
    setFormValue(formData);
    setEditorOpen(true);
  };

  return (
    <DefaultLayout>
      <Header>
        <h2>Users</h2>
      </Header>
      <Content>
        <FlexboxGrid justify="end" style={{ marginBottom: 20 }}>
          <FlexboxGrid.Item>
            <IconButton
              icon={<PlusIcon />}
              onClick={() => {
                setFormValue(_.cloneDeep(defaultFormValue));
                setEditorOpen(true);
              }}
              appearance="primary"
            >
              Add User
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <Drawer size="sm" placement="right" backdrop="static" open={editorOpen} onClose={() => setEditorOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>User</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => setEditorOpen(false)}>Cancel</Button>
              <Button onClick={() => submitForm()} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            {accountsLoading ? (
              <Loader />
            ) : (
              <Form
                onChange={(newValues) => {
                  const values = {};
                  Object.keys(newValues).forEach((key) => {
                    _.set(values, key, newValues[key]);
                  });
                  // @ts-ignore
                  setFormValue(values);
                }}
                formValue={formValue}
                fluid
              >
                <Field name="username" label="Username" />

                <Field
                  name="role"
                  label="Role"
                  accepter={InputPicker}
                  data={[
                    { label: "User", value: "USER" },
                    { label: "Admin", value: "ADMIN" },
                  ]}
                  block
                />

                <Field
                  name="accountIds"
                  label="Accounts"
                  accepter={TagPicker}
                  cleanable={true}
                  data={accounts.map((account: any) => ({ label: account.name, value: account.id }))}
                  block
                />

                <Field name="newPassword" label="Password" type="password" />
              </Form>
            )}
          </Drawer.Body>
        </Drawer>

        <Table
          data={users}
          loading={loading}
          onRowClick={(rowData, e) => {
            // @ts-ignore
            if (["svg", "path", "button"].includes(e.target.tagName.toLowerCase())) {
              return;
            }
            onEdit(rowData);
          }}
          rowClassName="cursor-pointer"
          autoHeight
          bordered
        >
          <Column>
            <HeaderCell>Username</HeaderCell>
            <Cell dataKey="username" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Role</HeaderCell>
            <Cell dataKey="role" />
          </Column>

          <Column width={200}>
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <ButtonToolbar>
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Delete</Tooltip>}>
                    <IconButton
                      size="xs"
                      icon={<TrashIcon />}
                      appearance="primary"
                      color="red"
                      onClick={() => setDeleteId(rowData.id)}
                    />
                  </Whisper>
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </Table>

        <ConfirmDialog
          open={deleteId ? true : false}
          onCancel={() => {
            setDeleteId("");
          }}
          onConfirm={() => deleteItem()}
        />
      </Content>
    </DefaultLayout>
  );
};

export default UsersPage;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "rsuite";
import { getCampaignLog } from "../../../api/campaign.api";

const { Column, HeaderCell, Cell } = Table;

const CampaignManagerLogTab: React.FC<{ campaignId: string }> = (props) => {
  const { campaignId } = props;

  const { accountId = "" } = useParams();

  const [loading, setLoading] = useState(false);
  const [log, setLog] = useState([]);

  const fetchLog = async () => {
    setLoading(true);
    const { data } = await getCampaignLog(accountId, campaignId);
    setLog(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!campaignId) {
      return;
    }

    fetchLog();
  }, [campaignId]);

  return (
    <Table data={log} autoHeight bordered loading={loading}>
      <Column width={200}>
        <HeaderCell>Start Time</HeaderCell>
        <Cell>{(rowData) => <>{rowData?.startTime ? new Date(rowData.startTime).toLocaleString() : "-"}</>}</Cell>
      </Column>

      <Column width={200}>
        <HeaderCell>End Time</HeaderCell>
        <Cell>{(rowData) => <>{rowData?.startTime ? new Date(rowData.endTime).toLocaleString() : "-"}</>}</Cell>
      </Column>

      <Column width={200}>
        <HeaderCell>Ready Time</HeaderCell>
        <Cell>{(rowData) => <>{rowData?.startTime ? new Date(rowData.nextRunTime).toLocaleString() : "-"}</>}</Cell>
      </Column>

      <Column flexGrow={1}>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey="status" />
      </Column>
    </Table>
  );
};

export default CampaignManagerLogTab;

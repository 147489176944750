import _ from "lodash";
import { createApiClient } from "./client";

export const getCampaigns = async (accountId: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/campaigns`);
  return data;
};

export const getOneCampaign = async (accountId: string, id: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/campaigns/${id}`);
  return data;
};

export const createCampaign = async (accountId: string, dto: any) => {
  const client = createApiClient();
  const { data } = await client.post(`/${accountId}/campaigns`, dto);
  return data;
};

export const updateCampaign = async (accountId: string, id: string, dto: any) => {
  const client = createApiClient();
  const { data } = await client.patch(`/${accountId}/campaigns/${id}`, dto);
  return data;
};

export const saveCampaign = async (accountId: string, data: any) => {
  if (data?.id) {
    const result = await updateCampaign(accountId, data.id, _.omit(data, ["id"]));
    return result;
  }

  const result = await createCampaign(accountId, data);
  return result;
};

export const deleteCampaign = async (accountId: string, id: string) => {
  const client = createApiClient();
  await client.delete(`/${accountId}/campaigns/${id}`);
  return true;
};

export const getCampaignLog = async (accountId: string, id: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/campaigns/${id}/log`);
  return data;
};

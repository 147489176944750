import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonToolbar, Col, Container, Content, FlexboxGrid, Form, Panel } from "rsuite";
import { loginWithBasicAuth } from "../api/auth.api";
import { useGlobalState } from "../state";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const globalState = useGlobalState();

  const [state, setState] = useState({ username: "", password: "" });

  const onSubmit = async () => {
    if (!state.username || !state.password) {
      return;
    }

    const resp = await loginWithBasicAuth(state.username, state.password);
    localStorage.setItem("token", resp.token);
    globalState.user.set(resp.user);

    navigate("/select-account");
  };

  return (
    <Container>
      <Content>
        <FlexboxGrid justify="center" style={{ marginTop: 40 }}>
          <FlexboxGrid.Item as={Col} colspan={24} md={6} lg={4} style={{ background: "var(--rs-primary-100)" }}>
            <Panel header={<h3>Login</h3>}>
              <Form fluid onSubmit={onSubmit}>
                <Form.Group>
                  <Form.ControlLabel>Username</Form.ControlLabel>
                  <Form.Control
                    name="username"
                    value={state.username}
                    onChange={(newValue: string) => setState({ ...state, username: newValue })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Password</Form.ControlLabel>
                  <Form.Control
                    name="password"
                    type="password"
                    autoComplete="off"
                    value={state.password}
                    onChange={(newValue: string) => setState({ ...state, password: newValue })}
                  />
                </Form.Group>
                <Form.Group>
                  <ButtonToolbar>
                    <Button appearance="primary" type="submit">
                      Sign in
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
};

export default LoginPage;

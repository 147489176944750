import { useEffect, useState } from "react";
import { Message, toaster } from "rsuite";
import { getSocialAccounts } from "../api/social.api";

export const useSocialAccounts = (props: { accountId: string; provider?: string }) => {
  const { accountId = "", provider = "" } = props;

  const [data, setData] = useState([]);

  const refetch = async () => {
    try {
      const response = await getSocialAccounts({ accountId, provider });
      setData(response.data);
    } catch (err: any) {
      toaster.push(
        <Message type="error" header="Error" showIcon>
          {err.message}
        </Message>
      );
      console.log(err);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return { data, refetch };
};

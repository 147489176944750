import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonToolbar, Content, FlexboxGrid, Header, IconButton, Message, Table, toaster, Tooltip, Whisper } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { deleteCampaign, getCampaigns } from "../../api/campaign.api";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import DefaultLayout from "../../layouts/DefaultLayout";

const CampaignsPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { accountId = "" } = useParams();

  // list
  const [data, setData] = useState([]);
  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const response = await getCampaigns(accountId);
      setData(response.data);
      setLoading(false);
    } catch (err: any) {
      toaster.push(
        <Message type="error" header="Error" showIcon>
          {err.message}
        </Message>
      );
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  // delete
  const [deleteId, setDeleteId] = useState("");
  const deleteItem = async () => {
    if (!deleteId) {
      return;
    }

    try {
      await deleteCampaign(accountId, deleteId);
      setDeleteId("");
      fetchCampaigns();
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Campaign deleted!
        </Message>
      );
    } catch (err) {
      toaster.push(<Message type="error">Error!!!!</Message>);
      console.log(err);
    }
  };

  return (
    <DefaultLayout>
      <Header>
        <h2>Campaigns</h2>
      </Header>
      <Content>
        <FlexboxGrid justify="end" style={{ marginBottom: 20 }}>
          <FlexboxGrid.Item>
            <Link to={`/${accountId}/campaigns/manager`}>
              <IconButton icon={<PlusIcon />} appearance="primary">
                Add Campaign
              </IconButton>
            </Link>
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <Table data={data} loading={loading} autoHeight bordered>
          <Column flexGrow={2}>
            <HeaderCell>Target URL</HeaderCell>
            <Cell dataKey="targetUrl">
              {(rowData: any) => <Link to={`/${accountId}/campaigns/manager/${rowData.id}`}>{rowData.targetUrl}</Link>}
            </Cell>
          </Column>

          <Column>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>

          <Column width={200}>
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <ButtonToolbar>
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Delete</Tooltip>}>
                    <IconButton
                      size="xs"
                      icon={<TrashIcon />}
                      appearance="primary"
                      color="red"
                      onClick={() => setDeleteId(rowData.id)}
                    />
                  </Whisper>
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </Table>

        <ConfirmDialog
          open={deleteId ? true : false}
          onCancel={() => {
            setDeleteId("");
          }}
          onConfirm={() => deleteItem()}
        />
      </Content>
    </DefaultLayout>
  );
};

export default CampaignsPage;

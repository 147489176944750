import _ from "lodash";
import { createApiClient } from "./client";

export const getTrafficSources = async (accountId: string) => {
  const client = createApiClient();
  const { data } = await client.get(`/${accountId}/traffic-sources`);
  return data;
};

export const createTrafficSource = async (accountId: string, dto: any) => {
  const client = createApiClient();
  const { data } = await client.post(`/${accountId}/traffic-sources`, dto);
  return data;
};

export const updateTrafficSource = async (accountId: string, id: string, dto: any) => {
  const client = createApiClient();
  const { data } = await client.patch(`/${accountId}/traffic-sources/${id}`, dto);
  return data;
};

export const saveTrafficSource = async (accountId: string, data: any) => {
  if (data?.id) {
    const result = await updateTrafficSource(accountId, data.id, _.omit(data, ["id"]));
    return result;
  }

  const result = await createTrafficSource(accountId, data);
  return result;
};

export const deleteTrafficSource = async (accountId: string, id: string) => {
  const client = createApiClient();
  await client.delete(`/${accountId}/traffic-sources/${id}`);
  return true;
};
